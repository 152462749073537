import styled from '@emotion/styled'

export const KeapPartnerHomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

export const KeapPartnerMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  flex-grow: 1;
  align-items: center;
`

export const KeapPartnerTitle = styled.h1`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 2rem;
  font-family: var(--bs-body-font-family);
`

export const KeapPartnerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1442px;
`

export const KeapPartnerSection = styled.section`
  border: 1px solid rgba(222, 226, 230, 50%);
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  max-width: 1442px;
`

export const KeapPartnerSectionTitle = styled.h2`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: var(--bs-body-font-family);
`

export const KeapPartnerArticle = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-block: 1rem;
`

export const KeapPartnerSectionParagraph = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
  font-family: var(--bs-body-font-family);
`

export const KeapPartnerImagesContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  width: 100%;
  padding: 1rem;
`

export const KeapPartnerImageLink = styled.a`
  display: flex;
  flex-grow: 1;
  height: auto;
  width: auto;
`

export const KeapPartnerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export const KeapPartnerSidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid rgba(222, 226, 230, 50%);
  padding: 1.5rem;
  row-gap: 1rem;
`

export const KeapPartnerSidebarSubtitle = styled.h3`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1.25rem;
  font-family: var(--bs-body-font-family);
`

export const KeapPartnerSidebarMutedText = styled.p`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  color: rgb(129, 129, 129);
`
export const KeapPartnerSidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const KeapPartnerSidebarLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  background-color: #b3e6b2;
  width: 100%;
  min-width: max-content;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  border-radius: 0.5rem;
  font-weight: 600;
  color: #1a4d19;

  & div {
    filter: brightness(50%);
  }

  &:hover {
    color: #359b31;
  }

  &:hover div {
    display: flex;
    position: relative;
    filter: brightness(100%);
  }

  &:hover div::after {
    content: '';
    position: absolute;
    width: 120%;
    height: 0.09rem;
    background-color: #359b31;
    left: 0;
    bottom: 0.1rem;
    right: 0;
  }
`

export const KeapPartnerSidebarBlueLink = styled(KeapPartnerSidebarLink)`
  background-color: rgba(9, 101, 243, 0.11);
  color: rgb(7, 126, 244);
  &:hover {
    color: rgb(7, 126, 244);
  }
`

export const KeapPartnerLogo = styled.div`
  width: 40.75px;
  height: 1.25rem;
  background-size: contain;
  background-color: transparent;
`

export const KeapPartnerSidebarDivider = styled.div`
  padding-block: 1rem;
`
