// import React, {useState, useEffect, useCallback} from 'react'
import React from 'react'
import {Modal, ModalTitle} from '@thryvlabs/maverick'

const BillingNoticeModal = ({
  setIsBillingNoticeDisplayed,
  calledFromPaymentLink = false,
}) => {
  console.log('setIsBillingNoticeDisplayed :: ', setIsBillingNoticeDisplayed)
  const handleContinue = () => {
    console.log('Inside handleContinue of BillingNoticeModal')
    setIsBillingNoticeDisplayed(true)
  }
  if (calledFromPaymentLink) {
    return (
      <Modal
        btnText="Continue"
        btnType={'primary'}
        width={{maxWidth: '600px', width: '90%'}}
        action
        footer
        btnActionText="Continue"
        btnAction={null}
        onClose={null}
        actionClose={true}
        openOnLoad
        altBtn={<></>}
      >
        <div className="text-center">
          <ModalTitle variant="subtitle" size="lg">
            Billing Notice.
          </ModalTitle>
        </div>
        <div>
          <b>Important Notice:</b>
          <p>
            Adjusting your program may result in prorated charges or credits.
            Please allow 3-5 business days for prorated charges or refunds to
            process before addressing any billing concerns.
          </p>
          <p>Thank you for your understanding!</p>
        </div>
      </Modal>
    )
  } else {
    return (
      <Modal
        btnText="Continue"
        btnType={'primary'}
        width={{maxWidth: '600px', width: '90%'}}
        action
        footer
        btnActionText="Continue"
        btnAction={handleContinue}
        onClose={null}
      >
        <div className="text-center">
          <ModalTitle variant="subtitle" size="lg">
            Billing Notice.
          </ModalTitle>
        </div>
        <div>
          <b>Important Notice:</b>
          <p>
            Adjusting your program may result in prorated charges or credits.
            Please allow 3-5 business days for prorated charges or refunds to
            process before addressing any billing concerns.
          </p>
          <p>Thank you for your understanding!</p>
        </div>
      </Modal>
    )
  }
}

export {BillingNoticeModal}
