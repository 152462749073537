import React from 'react'
import {useAsync} from '../../utils/use-async'
import {useAuth0} from '@auth0/auth0-react'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'

/**
 * @type {React.Context<{
 *  isGenerateLinkModalOpen: boolean,
 *  openGenerateLinkModal: ()=>void;
 *  closeGenerateLinkModal: ()=>void;
 *  toggleGenerateLinkModal: ()=>void;
 *  selectedAppCodes:  string[]
 *  setSelectedAppCodes: (value: string[])=>void,
 *  handleSubmit: ()=>Promise<void>
 *  couponCode: string | undefined,
 *  setCouponCode: (string)=>void;
 *  keapPartnerId: string,
 *  generatedUrl: string | undefined,
 *  plans: { centers: { code: string, id: string, name: string, currencies: { currency: string, unitAmount: number }[] }[], apps: { code: string, id: string, name: string, currencies: { currency: string, unitAmount: number }[] }[] }
 * }>}
 */
const GenerateBuyNowLinkContext = React.createContext(undefined)

/**
 *
 * @param {{
 *    children: React.ReactNode,
 *    keapPartnerId: string,
 *    sfPartnerAccountId: string,
 *    partnerEmail: string,
 *    partnerFirstName: string,
 *    partnerLastName: string,
 * }} props
 * @returns
 */
export function GenerateBuyNowLinkProvider({userData, children}) {
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  const {user} = useAuth0()
  const keapPartnerUser = userData

  const [selectedPlan, setSelectedPlan] = React.useState([])
  const [selectedAddon, setSelectedAddon] = React.useState([])

  const [kpSelectedCoupon, setkpSelectedCoupon] = React.useState()
  const [defaultIncentiveCoupon, setDefaultIncentiveCoupon] = React.useState()
  const [onboardingFeeCoupon, setOnboardingFeeCoupon] = React.useState([])

  const [plans, setPlans] = React.useState()
  const [generatedUrl, setGeneratedUrl] = React.useState()
  const [selectedCountry] = React.useState('US')
  const [clientGroup] = React.useState('keap-partner')
  const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] =
    React.useState(false)
  const obFeeCoupon = {
    code: 'keap-partner-waive-obfee',
  }

  const openGenerateLinkModal = React.useCallback(() => {
    console.log('selected plan', selectedPlan)
    setIsGenerateLinkModalOpen(true)
  }, [])

  const closeGenerateLinkModal = React.useCallback(() => {
    setIsGenerateLinkModalOpen(false)
    setSelectedAddon([])
    setGeneratedUrl('')
    setkpSelectedCoupon([])
  }, [])

  const toggleGenerateLinkModal = React.useCallback(() => {
    setIsGenerateLinkModalOpen(prev => {
      const newValue = !prev
      if (!newValue) setSelectedAddon([])
      return newValue
    })
  }, [])

  async function handleSubmit() {
    const incentiveCoupon =
      Array.isArray(defaultIncentiveCoupon) &&
      defaultIncentiveCoupon.length > 0 &&
      defaultIncentiveCoupon.map(coupon => {
        return coupon.code
      })
    const appliedC =
      Array.isArray(kpSelectedCoupon) &&
      kpSelectedCoupon.length > 0 &&
      kpSelectedCoupon.map(coupon => {
        return coupon.code
      })

    const selectedPlanCodes =
      Array.isArray(selectedPlan) && selectedPlan?.map(plan => plan.code)
    const selectedAddonCodes =
      Array.isArray(selectedAddon) && selectedAddon?.map(addon => addon.code)

    const {generatedUrl} = await client('cc/order/keap-partner/generate-link', {
      data: {
        sfPartnerAccountId: keapPartnerUser?.user_metadata?.sfPartnerAccountId,
        keapPartnerId: keapPartnerUser?.user_metadata?.keapPartnerId,
        partnerEmail: keapPartnerUser?.email,
        partnerFirstName: user.firstName,
        partnerLastName: user.LastName,
        planCode: selectedPlanCodes,
        addOnCode: selectedAddonCodes,
        couponCode: Array.isArray(appliedC) ? appliedC : null,
        defaultIncentive: Array.isArray(incentiveCoupon)
          ? incentiveCoupon
          : null,
      },
    })
    // const generatedUrl = 'https://ooecp.thryv.com/?LinkId=12345'
    setGeneratedUrl(generatedUrl)
  }

  const validateAndApplyIncentiveCoupons = async coupons => {
    let finalCoupons = []
    for (let key of Object.keys(coupons)) {
      console.log(
        '<<<<<< coupon array of' +
          key +
          ' >>>>>> :: ' +
          JSON.stringify(coupons[key]),
      )
      let selectedCoupon = coupons[key].filter(item => item.isChecked)
      console.log(
        '<<<<<< Selected coupon is before validating >>>>>> :: ' +
          JSON.stringify(selectedCoupon),
      )
      const codes = []
      codes.push(selectedPlan?.map(plan => plan.code))

      if (selectedCoupon && selectedCoupon.length > 0) {
        let couponValidationUrl = `orders/coupon/validate?couponCode=${selectedCoupon[0].coupon_code}&country=${selectedCountry}&planCode=${selectedCoupon[0].plan_code}`
        let couponResponse = await client(couponValidationUrl)
        if (couponResponse.isValid) {
          finalCoupons.push(couponResponse)
        } else {
          console.log(
            '<<<<<< Invalid coupon : ' +
              selectedCoupon[0].coupon_code +
              ' >>>>>>',
          )
        }
      }
    }
    console.log(
      '<<<<<< validate all eligible incentive coupons >>>>>> ::' +
        JSON.stringify(finalCoupons),
    )
    if (finalCoupons && finalCoupons.length > 0) {
      setDefaultIncentiveCoupon(finalCoupons)
      // toast.success('Keap Partner Incentive Coupon Applied!.')
    }
  }

  const fetchDefaultIncentiveCoupons = async () => {
    const codes = []
    codes.push(selectedPlan?.map(plan => plan.code))
    if (selectedCountry && codes && codes.length > 0) {
      let response = await client(
        `cc/order/fetchPlanCoupons/${selectedCountry}/${codes.join(
          ',',
        )}/${clientGroup}`,
      )
      console.log(
        '<<<<<< fetched all incentive coupons >>>>>> :: ' +
          JSON.stringify(response),
      )
      if (response && response.length > 0) {
        let groupedCoupons = {}
        for (let coupon of response) {
          // Check if coupon is applicable, based on the new client only flag
          if (coupon?.new_client_only_flag === 'Y') {
            coupon['couponApplicable'] = false
          } else {
            coupon['couponApplicable'] = true
          }

          // Check if the coupon is auto applicable or not and add isChecked attribute accordingly
          if (coupon.new_auto_apply === 'Y') {
            // New client and coupon is auto applied for new clients
            coupon['isChecked'] = true
          } else {
            coupon['isChecked'] = false
          }
          coupon['isDisabled'] = false
        }
        let couponsTobeDisplayed = response.filter(
          coupon => coupon.couponApplicable,
        )
        // Group the coupons based on plan code
        couponsTobeDisplayed.forEach(coupon => {
          if (!groupedCoupons[coupon.plan_desc]) {
            groupedCoupons[coupon.plan_desc] = []
          }
          groupedCoupons[coupon.plan_desc].push(coupon)
        })
        for (let key of Object.keys(groupedCoupons)) {
          if (groupedCoupons[key].length === 1) {
            groupedCoupons[key][0].isDisabled = false
          }
        }
        console.log(
          '<<<<<< Eligible Coupons - grouped Coupons >>>>>> :: ',
          groupedCoupons,
        )
        validateAndApplyIncentiveCoupons(groupedCoupons)
      }
    }
  }

  const fetchAndValidateOnboardingFeeCoupon = async () => {
    if (obFeeCoupon && obFeeCoupon.code) {
      const codes = []
      codes.push(selectedPlan.map(plan => plan.code))
      let couponValidationUrl = `orders/coupon/validate?couponCode=${
        obFeeCoupon.code
      }&country=${selectedCountry}&planCode=${codes.join(',')}`

      let couponResponse = await client(couponValidationUrl)
      console.log(
        '<<<<<< onboarding fee coupons >>>>>> :: ' +
          JSON.stringify(couponResponse),
      )
      if (couponResponse.isValid) {
        console.log(
          '<<<<<< valid waive onboarding fee coupons >>>>>> :: ' +
            JSON.stringify(couponResponse),
        )
        if (couponResponse) {
          setOnboardingFeeCoupon([couponResponse])
          // toast.success('Keap Partner Waive Onboarding Fee Coupon Applied!.')
        }
      } else {
        console.log('<<<<<< Invalid onboarding fee coupon >>>>>> :: ')
      }
    }
  }

  const initModal = async () => {
    console.log('selected plan', selectedPlan)
    if (!generatedUrl) {
      // const marketingCenterPlusPlan = plans?.centers.find(
      //   ({code}) => code === 'mc_plus-5-thryv-m2m',
      // )
      // setSelectedPlan([marketingCenterPlusPlan])

      await fetchAndValidateOnboardingFeeCoupon()
      await fetchDefaultIncentiveCoupons()
    }
  }

  React.useEffect(() => {
    // try {
    //   const getKeapPartnerUser = async () => {
    //     const response = await run(client('user/keapPartnerUser'))
    //     console.log('<<<<<< Keap Partner user details >>>>>>', response)
    //     setKeapPartnerUser(response)
    //   }
    //   getKeapPartnerUser()
    // } catch (error) {
    //   console.log(
    //     '<<<<<< Error in fetching Keap Partner user details >>>>>>',
    //     error,
    //   )
    //   toast.error('Error in fetching Keap Partner user details :: ' + error)
    // }

    // // userData ? setKeapPartnerUser(userData) : setKeapPartnerUser(userData)
    try {
      const getPlans = async () => {
        const response = await run(client('plans/list/all/keap-partner-plans'))
        console.log('<<<<<< Keap Partner user details >>>>>>', response)
        setPlans(response)
        const marketingCenterPlusPlan = response?.centers.find(
          ({code}) => code === 'mc_plus-5-thryv-m2m',
        )
        setSelectedPlan([marketingCenterPlusPlan])
        console.log('selected plan - getPlans', selectedPlan)
      }
      getPlans()
    } catch (error) {
      console.log(
        '<<<<<< Error in fetching Plans for Keap Partner >>>>>>',
        error,
      )
      toast.error('Error in fetching Plans for Keap Partner :: ' + error)
    }
  }, [])

  return (
    <GenerateBuyNowLinkContext.Provider
      value={{
        selectedPlan,
        setSelectedPlan,
        selectedAddon,
        setSelectedAddon,
        kpSelectedCoupon,
        setkpSelectedCoupon,
        isGenerateLinkModalOpen,
        openGenerateLinkModal,
        closeGenerateLinkModal,
        toggleGenerateLinkModal,
        handleSubmit,
        initModal,
        onboardingFeeCoupon,
        defaultIncentiveCoupon,
        setDefaultIncentiveCoupon,
        selectedCountry,
        clientGroup,
        keapPartnerId: keapPartnerUser?.user_metadata?.keapPartnerId,
        plans,
        generatedUrl,
      }}
    >
      {children}
    </GenerateBuyNowLinkContext.Provider>
  )
}

export function useGenerateBuyLinkContext() {
  const context = React.useContext(GenerateBuyNowLinkContext)
  if (!context) {
    throw new Error(
      'useGenerateBuyLinkContext cannot be used outside of GenerateBuyNowLinkProvider',
    )
  }
  return context
}
