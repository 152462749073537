import React from 'react'
import {
  KeapPartnerArticle,
  KeapPartnerHeader,
  KeapPartnerHomeWrapper,
  KeapPartnerImage,
  KeapPartnerImageLink,
  KeapPartnerImagesContainer,
  KeapPartnerLogo,
  KeapPartnerMainContentContainer,
  KeapPartnerSection,
  KeapPartnerSectionParagraph,
  KeapPartnerSectionTitle,
  KeapPartnerSidebarBlueLink,
  KeapPartnerSidebarContainer,
  KeapPartnerSidebarDivider,
  KeapPartnerSidebarLink,
  KeapPartnerSidebarMutedText,
  KeapPartnerSidebarNav,
  KeapPartnerSidebarSubtitle,
  KeapPartnerTitle,
} from './styled.components'
import {useAuth0} from '@auth0/auth0-react'
import {toast} from 'react-toastify'
import {
  keapPartnerLogo,
  thryvMCListImg,
  thryvMCPdfImg,
  ThryvMCVideoImg,
} from '../../../assets'
import {marketingCenterPDF} from '../../../assets/documents/tools/tools-docs'
import {GenerateBuyNowLink} from '../../../components/generate-buy-now-link'
import {useAuthClient} from '../../../utils/use-auth-client'

function HomeKeapPartner() {
  const {user} = useAuth0()
  const [userData, setUserData] = React.useState(null)
  const client = useAuthClient()

  const wellcomeName = React.useMemo(() => {
    if (!userData?.user_metadata?.firstname) return '...'
    return `${userData?.user_metadata?.firstname} ${userData?.user_metadata?.lastname}`
  }, [userData?.user_metadata])

  const fetchUserData = async () => {
    try {
      const url = 'user?email=' + user?.email
      const data = await client(url)
      if (data) {
        setUserData(data)
      }
    } catch (e) {
      toast.error('Failed to fetch profile data')
    }
  }

  React.useEffect(() => {
    fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <KeapPartnerHomeWrapper>
      <KeapPartnerMainContentContainer>
        <KeapPartnerHeader>
          <KeapPartnerTitle>Welcome {wellcomeName}</KeapPartnerTitle>
          <div>
            <GenerateBuyNowLink userData={userData} />
          </div>
        </KeapPartnerHeader>
        <KeapPartnerSection>
          <KeapPartnerImageLink
            href={marketingCenterPDF}
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerImage src={thryvMCPdfImg} alt="Marketing Center PDF" />
          </KeapPartnerImageLink>
          <KeapPartnerArticle>
            <KeapPartnerSectionTitle>
              Help your clients kick growth into high gear.
            </KeapPartnerSectionTitle>
            <KeapPartnerSectionParagraph>
              Small business marketing software that it easy for your clients to
              run ads, boost their online presence, understand their customers
              and make data-driven decisions.
            </KeapPartnerSectionParagraph>
          </KeapPartnerArticle>

          <KeapPartnerArticle>
            <KeapPartnerSectionTitle>
              What is Thryv Marketing Center?
            </KeapPartnerSectionTitle>

            <KeapPartnerImagesContainer>
              <KeapPartnerImageLink
                href="https://www.thryv.com/marketing-center/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: '60%',
                }}
              >
                <KeapPartnerImage src={thryvMCListImg} alt="Why Thryv MC" />
              </KeapPartnerImageLink>
              <KeapPartnerImageLink
                href="https://www.thryv.com/marketing-center/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <KeapPartnerImage
                  src={ThryvMCVideoImg}
                  alt="Marketing Center Video"
                />
              </KeapPartnerImageLink>
            </KeapPartnerImagesContainer>
          </KeapPartnerArticle>
        </KeapPartnerSection>
      </KeapPartnerMainContentContainer>

      <KeapPartnerSidebarContainer>
        <KeapPartnerSidebarSubtitle>Support</KeapPartnerSidebarSubtitle>
        <KeapPartnerSidebarMutedText>
          Toll free for U.S.
        </KeapPartnerSidebarMutedText>

        <KeapPartnerSidebarDivider />

        <KeapPartnerSidebarSubtitle>Resources</KeapPartnerSidebarSubtitle>
        <KeapPartnerSidebarNav>
          <KeapPartnerSidebarBlueLink
            href={'https://www.thryv.com/thryv-partner-program-agreement/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Thryv Partner Program Terms
          </KeapPartnerSidebarBlueLink>
          <KeapPartnerSidebarLink
            href={'https://infusionsoft.my.site.com/Partners/login?locale=us'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerLogo
              style={{
                backgroundImage: `url(${keapPartnerLogo})`,
              }}
            />{' '}
            Partner Portal
          </KeapPartnerSidebarLink>
          <KeapPartnerSidebarLink
            href={
              'https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1rdQ8hEBHyB6T5dHTYLzpOJFz1f0oHH8o%2Fview&data=05%7C02%7Claurinda.gorman%40thryv.com%7C95712e1cfee040e49bb108dd627412ce%7Cdbd3a8f5232741939559a36e9ccb40f9%7C0%7C0%7C638774972873022165%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=AxuDrhvZMU1UR5shC1%2FwgrYD7ycW1KmmWOYPe8VJlRQ%3D&reserved=0'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerLogo
              style={{
                backgroundImage: `url(${keapPartnerLogo})`,
              }}
            />{' '}
            Partner Success Program Guide
          </KeapPartnerSidebarLink>
        </KeapPartnerSidebarNav>
      </KeapPartnerSidebarContainer>
    </KeapPartnerHomeWrapper>
  )
}

export {HomeKeapPartner}
