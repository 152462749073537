import React, {useContext, useEffect, useState} from 'react'
import {
  sidebarToolsIcon,
  sidebarAppointmentsIcon,
  sidebarOrdersIcon,
  sidebarInvitationsIcon,
  sidebarBusinessScansIcon,
  sidebarProductShowcaseIcon,
  sidebarAccountsIcon,
  thryvSalesLogo,
} from '../assets'
import {Icon, Button, Modal, Input} from '@thryvlabs/maverick'
import {useLocation} from 'react-router'
import {useUserRoles} from '../utils/use-user-roles'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../utils/constants'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {headerContext} from '../context/header-context'
import {Link} from 'react-router-dom'
import {MobileSidebar, Sidebar, SidebarNavigationMenu} from './ui/sidebar'
import {NavLink} from 'react-router-dom'
import {useWindowDimensions} from '../utils/use-window-dimensions'

import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {toast} from 'react-toastify'
import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {useAuth0} from '@auth0/auth0-react'

function setAppointmentsSubLinks(roles) {
  const appointmentsSubLinks = []
  const {demoRequests, appointments} = AUTHORIZED_ROLES_BY_SCREENS

  if (getAuthByRole(demoRequests, roles)) {
    appointmentsSubLinks.push({
      name: 'Demo Requests',
      url: '/demo-requests',
    })
  }
  if (getAuthByRole(appointments, roles)) {
    appointmentsSubLinks.push({
      name: 'Onboarding',
      url: '/appointments',
    })
  }
  return appointmentsSubLinks
}

const keapUserformSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email')
    .min(3, 'Email must be at least 3 characters')
    .max(100, 'Email cannot be longer than 100 characters')
    .required(),
  firstName: yup
    .string()
    .min(3, 'First Name must be at least 3 characters')
    .max(100, 'First Name cannot be longer than 100 characters')
    .required(),
  lastName: yup
    .string()
    .min(3, 'Last Name must be at least 3 characters')
    .max(100, 'Last Name cannot be longer than 100 characters')
    .required(),
  sfPartnerAccountId: yup
    .string()
    .min(3, 'SF Partner Account Id must be at least 3 characters')
    .max(100, 'SF Partner Account Id cannot be longer than 100 characters'),
  keapPartnerId: yup
    .string()
    .min(3, 'Keap Partner Id must be at least 3 characters')
    .max(100, 'Keap Partner Id cannot be longer than 100 characters'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(100, 'Password cannot be longer than 100 characters')
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords does not match')
    .required(),
})

const CreateNewKeapPartnerModal = ({
  windowWidth,
  isSidebarOpen,
  isMobileSidebarOpen,
}) => {
  const client = useAuthClient()
  const {run, isLoading} = useAsync()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(keapUserformSchema),
  })

  const firstName = watch('firstName')
  const lastName = watch('lastName')

  useEffect(() => {
    const userName =
      (firstName ? firstName[0] : '') +
      (lastName ? lastName : '').replace(/\s+/g, '')
    setValue('userName', userName)
  }, [firstName, lastName, setValue])

  const handleCreateUsers = async data => {
    try {
      const response = await run(client('user/createkeappartneruser', {data}))
      if (response.hasWarningorError) {
        if (response.userCreated && response.error) {
          toast.success('User is created succefully.')
          toast.error(response.errorContext + ' - ' + response.error?.message)
        } else if (response.error) {
          toast.error(response?.error?.message)
        } else {
          toast.error('error in Create User')
        }
      } else {
        toast.success('User is created succefully.')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const defaultModalProps = {
    variant: 'secondary',
    btnText: 'Create keap User',
    btnType: 'secondary',
  }
  const collapsedModalProps = {
    altBtn: (
      <Icon
        variant="circleExclamation"
        type="regular"
        color="rgba(128,128,128)"
      />
    ),
  }
  let modalProps
  if (windowWidth > 835) {
    isSidebarOpen
      ? (modalProps = defaultModalProps)
      : (modalProps = collapsedModalProps)
  } else if (isMobileSidebarOpen) {
    modalProps = defaultModalProps
  }
  return (
    <Modal
      title="Create Keap User"
      disableBackdropClick
      primaryClose
      {...modalProps}
    >
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="First Name"
          aria-label="First Name"
          register={register}
          errors={errors}
          name="firstName"
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Last Name"
          aria-label="Last Name"
          register={register}
          errors={errors}
          name="lastName"
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="User Name"
          aria-label="user Name"
          name="userName"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Email"
          aria-label="Email"
          errors={errors}
          name="email"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Keap Partner ID"
          aria-label="Keap Partner ID"
          name="keapPartnerId"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="SF Partner Account Record ID"
          aria-label="SF Partner Account Record ID"
          name="sfPartnerAccountId"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="password"
          placeholder="Password"
          aria-label="Password"
          name="password"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="password"
          placeholder="Retype Password"
          aria-label="Retype Password"
          name="confirmPassword"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="w-100 text-center">
        <Button
          onClick={handleSubmit(handleCreateUsers)}
          variant="primary"
          className="mr-4"
          disabled={isLoading}
        >
          Create
        </Button>
      </div>
    </Modal>
  )
}

function SideNavBar({
  isSidebarOpen,
  setIsSidebarOpen,
  isMobileSidebarOpen,
  setIsMobileSidebarOpen,
}) {
  const location = useLocation()
  const {roles} = useUserRoles()
  const [activeItemId, setActiveItemId] = useState(null)
  const lDFlags = useFlags()
  const {width: windowWidth} = useWindowDimensions()
  const appointmentsSubLinks = setAppointmentsSubLinks(roles)
  const {isHeaderVisible} = useContext(headerContext)

  const [hasCreateKeapPartnerUserAccess, setHasCreateKeapPartnerUserAccess] =
    useState(false)
  const {user} = useAuth0()
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })

  let linksArray = [
    {
      name: 'Accounts',
      link: '/my-accounts',
      src: sidebarAccountsIcon,
    },
    {
      name: 'Invitations',
      link: '/invitations',
      src: sidebarInvitationsIcon,
      url: 'invitations',
    },
    {
      name: 'Product Showcase',
      link: '/productshowcase',
      url: 'productshowcase',
      src: sidebarProductShowcaseIcon,
    },
    {
      name: 'Tools',
      link: '/tools',
      url: 'tools',
      src: sidebarToolsIcon,
    },
  ]
  if (lDFlags.frontendShowScans) {
    linksArray.push({
      name: 'Business Scans',
      link: '/scans',
      src: sidebarBusinessScansIcon,
      url: 'scans',
    })
  }
  if (lDFlags.frontendDisplayNewOrder) {
    linksArray.push({
      name: 'Orders',
      link: '/orders',
      url: 'orders',
      src: sidebarOrdersIcon,
    })
  }
  if (roles.includes('yello')) {
    linksArray[0].name = 'My Accounts'
    delete linksArray[0].subLinks
  }
  if (appointmentsSubLinks.length > 0) {
    linksArray.splice(1, 0, {
      name: 'Appointments',
      link: '/demo-requests',
      src: sidebarAppointmentsIcon,
    })
  }

  const isAppointmentLocation = location.pathname === '/new-demo-request'
  const isAccountsLocation =
    location.pathname === '/' || location.pathname === '/all-accounts'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await run(
          client('plans/features/Keap_Account_Management', {method: 'GET'}),
        )
        for (let accessRow of response) {
          if (accessRow?.entity_type === 'User') {
            if (
              user?.email.toLowerCase() ===
              accessRow?.entity_value.toLowerCase()
            ) {
              setHasCreateKeapPartnerUserAccess(true)
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [client, run, user?.email])

  React.useEffect(() => {
    if (isAppointmentLocation) {
      setActiveItemId(linksArray[1].name)
    }
    if (isAccountsLocation) {
      setActiveItemId(linksArray[0].name)
    }

    // eslint-disable-next-line
  }, [isAppointmentLocation, isAccountsLocation])

  if (!isHeaderVisible) {
    return null
  }

  if (windowWidth > 835) {
    return (
      <Sidebar>
        {isSidebarOpen ? (
          <div
            style={{
              marginTop: '20px',
              width: '244px',
              height: '100%',
            }}
          >
            <div className="d-flex" style={{marginBottom: '22px'}}>
              <div className="toggle-button">
                <Icon
                  onClick={() => setIsSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={'bars'}
                  className={isSidebarOpen ? 'visible' : 'hidden'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
                <Icon
                  onClick={() => setIsSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={'chevronLeft'}
                  className={isSidebarOpen ? 'hidden' : 'visible'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
              </div>
              <Link
                to={'/'}
                onClick={() => setActiveItemId(linksArray[0].name)}
              >
                <img
                  src={thryvSalesLogo}
                  alt="Thryv Sales Logo"
                  style={{height: '40px', marginBottom: '3px'}}
                />
              </Link>
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex"
                style={{
                  marginTop: '13px',
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() =>
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                              fontSize: '12px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                marginRight: '10px',
                                width: '14px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                            {link.name}
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                {/* <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Button variant="secondary">Open Ticket</Button>
                </a> */}
                <div
                  className="d-flex flex-column"
                  style={{
                    margin: 'auto auto 5px',
                    width: 'max-content',
                  }}
                >
                  <div className="d-flex align-items-center">
                    {hasCreateKeapPartnerUserAccess && (
                      <CreateNewKeapPartnerModal
                        windowWidth={windowWidth}
                        isSidebarOpen={isSidebarOpen}
                        isMobileSidebarOpen={isMobileSidebarOpen}
                      />
                    )}
                  </div>
                  <a
                    target="_blank"
                    href="http://help.thryv.info"
                    rel="noreferrer"
                    style={{
                      textDecoration: 'none',
                      margin: '5px auto 0',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="secondary"
                      style={{
                        width: '100%',
                      }}
                    >
                      Open Ticket
                    </Button>
                  </a>
                </div>
              </div>
            </SidebarNavigationMenu>
          </div>
        ) : (
          <div>
            <div
              className="toggle-button"
              style={{marginTop: '46px', marginBottom: '9px'}}
            >
              <Icon
                onClick={() => setIsSidebarOpen(prev => !prev)}
                type="regular"
                variant={'bars'}
                className={isSidebarOpen ? 'hidden' : 'visible'}
                style={{margin: '18px', width: '17px', marginTop: '-16px'}}
                color={'gray'}
                id="sidebar-toggle"
              />
              <Icon
                onClick={() => setIsSidebarOpen(prev => !prev)}
                type="regular"
                variant={'chevronRight'}
                className={isSidebarOpen ? 'visible' : 'hidden'}
                style={{margin: '18px', width: '17px', marginTop: '-16px'}}
                color={'gray'}
                id="sidebar-toggle"
              />
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex mt-4"
                style={{
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() =>
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                width: '17px',
                                margin: '0px 2px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                {/* <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Icon
                    variant="circleExclamation"
                    type="regular"
                    color="rgba(128,128,128)"
                  />
                </a> */}
                <div
                  className="d-flex flex-column"
                  style={{
                    margin: 'auto auto 5px',
                    width: 'max-content',
                  }}
                >
                  <div className="d-flex align-items-center">
                    {hasCreateKeapPartnerUserAccess && (
                      <CreateNewKeapPartnerModal
                        windowWidth={windowWidth}
                        isSidebarOpen={isSidebarOpen}
                        isMobileSidebarOpen={isMobileSidebarOpen}
                      />
                    )}
                  </div>
                  <a
                    target="_blank"
                    href="http://help.thryv.info"
                    rel="noreferrer"
                    style={{
                      textDecoration: 'none',
                      margin: '5px auto 0',
                      width: '100%',
                    }}
                  >
                    <Icon
                      variant="circleExclamation"
                      type="regular"
                      color="rgba(128,128,128)"
                    />
                  </a>
                </div>
              </div>
            </SidebarNavigationMenu>
          </div>
        )}
      </Sidebar>
    )
  } else {
    return (
      <MobileSidebar>
        {isMobileSidebarOpen && (
          <div
            style={{
              marginTop: '10px',
              width: '244px',
              height: '100%',
            }}
          >
            <div className="d-flex">
              <div className="toggle-button">
                <Icon
                  onClick={() => setIsMobileSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={isMobileSidebarOpen ? 'x' : 'bars'}
                  className={isMobileSidebarOpen ? 'visible' : 'hidden'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
              </div>
              <Link
                to={'/'}
                onClick={() => setActiveItemId(linksArray[0].name)}
              >
                <img
                  src={thryvSalesLogo}
                  alt="Thryv Sales Logo"
                  style={{height: '40px', marginBottom: '3px'}}
                />
              </Link>
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex"
                style={{
                  marginTop: '13px',
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isMobileSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() => {
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                            setIsMobileSidebarOpen(false)
                          }}
                        >
                          <div
                            className="sidebar-toggle"
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                marginRight: '10px',
                                width: '14px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                            {link.name}
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                {/* <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Button variant="secondary">Open Ticket</Button>
                </a> */}
                <div
                  className="d-flex flex-column"
                  style={{
                    margin: 'auto auto 5px',
                    width: 'max-content',
                  }}
                >
                  <div className="d-flex align-items-center">
                    {hasCreateKeapPartnerUserAccess && (
                      <CreateNewKeapPartnerModal
                        windowWidth={windowWidth}
                        isSidebarOpen={isSidebarOpen}
                        isMobileSidebarOpen={isMobileSidebarOpen}
                      />
                    )}
                  </div>
                  <a
                    target="_blank"
                    href="http://help.thryv.info"
                    rel="noreferrer"
                    style={{
                      textDecoration: 'none',
                      margin: '5px auto 0',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="secondary"
                      style={{
                        width: '100%',
                      }}
                    >
                      Open Ticket
                    </Button>
                  </a>
                </div>
              </div>
            </SidebarNavigationMenu>
          </div>
        )}
      </MobileSidebar>
    )
  }
}

export {SideNavBar}
